import React, { FunctionComponent, useMemo } from 'react'
import styles from './FeatureBlock.module.scss'
import { FeatureBlockProps } from './FeatureBlock.model'
import {
  AGENDA_OPTIONS,
  FeaturesType,
  INSI_VALIDATION_OPTIONS,
  MSS_PROTOCOLS,
  VITAL_CARD_SERVICE_OPTIONS,
} from '../../../../model/Preference'
import { SelectInput, Switch, FormBlock } from '../../../shared'
import RoleRestriction from '../../../../containers/RoleRestriction'
import { Roles } from '../../../../model/Roles'
import { InformationAlert } from '../InformationAlert'
import { isDoctor } from '../../../../misc/user.utilities'
import { ExternalServices } from '../../../../model/User'

export const FeatureBlock: FunctionComponent<FeatureBlockProps> = ({
  user,
  features,
  onChange,
  onAgendaChange,
  onInsiValidationChange,
  onVitalCardServiceChange,
}) => {
  const hasSephiraAccount = useMemo(
    () =>
      isDoctor(user) &&
      user.externalAccount?.some(
        (externalAccount) => externalAccount.serviceName === ExternalServices.SEPHIRA,
      ),
    [user],
  )
  return (
    <div>
      <FormBlock label="Fonctionnalités" icon="profile">
        <div className={styles.features}>
          <div className={styles.dropdownItem}>
            <SelectInput
              title="Agenda"
              options={AGENDA_OPTIONS}
              value={AGENDA_OPTIONS.find(({ value }) => value === features.agenda)}
              onSelect={(option) => onAgendaChange(option.value)}
            />
          </div>
          <div className={styles.featuresItem}>
            <SelectInput
              title="Service lecture vitale"
              options={VITAL_CARD_SERVICE_OPTIONS}
              value={VITAL_CARD_SERVICE_OPTIONS.find(
                ({ value }) => value === features.vitalCardService,
              )}
              onSelect={(option) => onVitalCardServiceChange(option.value)}
            />
          </div>
          {hasSephiraAccount && (
            <InformationAlert>Il y a déjà un compte rattaché à cet utilisateur</InformationAlert>
          )}
          <div className={styles.featuresItem}>
            <Switch
              name="Aide à la prescription"
              checked={features.drug}
              onChange={(checked) => onChange(FeaturesType.drug, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="Questionnaire à distance"
              checked={features.distantForm}
              onChange={(checked) => onChange(FeaturesType.distantForm, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="Messagerie de santé sécurisée (MSS)"
              checked={features.mail}
              onChange={(checked) => onChange(FeaturesType.mail, checked)}
            />
          </div>
          <div className={styles.dropdownItem}>
            <SelectInput
              title="Protocole MSS"
              options={MSS_PROTOCOLS}
              value={MSS_PROTOCOLS.find(({ value }) => value === features.mssSmtp)}
              onSelect={(option) => onChange(FeaturesType.mssSmtp, option.value)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="MSS : Envoi de pièces jointes"
              checked={features.mailAttachments}
              onChange={(checked) => onChange(FeaturesType.mailAttachments, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="DMP"
              checked={features.dmp}
              onChange={(checked) => onChange(FeaturesType.dmp, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="Adel santé"
              checked={features.adel}
              onChange={(checked) => onChange(FeaturesType.adel, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="Module Gynécologie"
              checked={features.gynecology}
              onChange={(checked) => onChange(FeaturesType.gynecology, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="Mises à jour Doctolib"
              checked={features.doctolibUpdates}
              onChange={(checked) => onChange(FeaturesType.doctolibUpdates, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="Parapheur"
              checked={features.documentTask}
              onChange={(checked) => onChange(FeaturesType.documentTask, checked)}
            />
          </div>
          <div className={styles.featuresItem}>
            <Switch
              name="Ajout patient destinataire MSS"
              checked={features.addPatientRecipient}
              onChange={(checked) => onChange(FeaturesType.addPatientRecipient, checked)}
            />
          </div>
        </div>
      </FormBlock>
      <RoleRestriction roles={[Roles.ADMIN_TECH]}>
        <FormBlock label="Fonctionnalités Ségur - Danger zone" icon="forbidden">
          <div className={styles.features}>
            <div className={styles.dropdownItem}>
              <SelectInput
                title="Validation INSi"
                options={INSI_VALIDATION_OPTIONS}
                value={INSI_VALIDATION_OPTIONS.find(
                  ({ value }) => value === features.insiValidation,
                )}
                onSelect={(option) => onInsiValidationChange(option.value)}
              />
            </div>
            <div className={styles.featuresItem}>
              <Switch
                name="DMP Efficience"
                checked={features.dmpInApp}
                onChange={(checked) => onChange(FeaturesType.dmpInApp, checked)}
              />
            </div>
            <div className={styles.featuresItem}>
              <Switch
                name="Récup auto INSi MSS"
                checked={features.mssAutoInsiFetch}
                onChange={(checked) => onChange(FeaturesType.mssAutoInsiFetch, checked)}
              />
            </div>
            <div className={styles.featuresItem}>
              <Switch
                name="ePrescription Ordoclic"
                checked={features.ePrescription}
                onChange={(checked) => onChange(FeaturesType.ePrescription, checked)}
              />
            </div>
            <div className={styles.featuresItem}>
              <Switch
                name="CDA"
                checked={features.cda}
                onChange={(checked) => onChange(FeaturesType.cda, checked)}
              />
            </div>
            <div className={styles.featuresItem}>
              <Switch
                name="Nom de document au format SEGUR"
                checked={features.segurDocumentName}
                onChange={(checked) => onChange(FeaturesType.segurDocumentName, checked)}
              />
            </div>
            <div className={styles.featuresItem}>
              <Switch
                name="Extraction de patient via la MSS"
                checked={features.mssExtractPatient}
                onChange={(checked) => onChange(FeaturesType.mssExtractPatient, checked)}
              />
            </div>
          </div>
        </FormBlock>
      </RoleRestriction>
    </div>
  )
}
